@use '../colors'

.container
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  background-color: white
  color: black
  width: 100%

.product_content
  display: flex

.famous_votes
  font-size: 0.5rem

.upvote
  display: flex
  justify-content: space-around
  align-items: center
  border: 1px solid colors.$l-grey
  border-radius: 5px
  width: 5.3rem
  height: 3.2rem
  padding: 0.6rem
  font-weight: lighter
  font-size: 0.9rem

.logo
  width: 5rem
  height: 5rem
  border-radius: 10px
  margin-right: 1rem

.product_name
  color: colors.$d-blue
