@use '../colors'

.container
  display: flex
  justify-content: space-between
  align-items: center
  padding: 30px 30px 10px 30px
  border-bottom: 1px solid colors.$l-grey

.title
  color: colors.$l-blue
  font-family: "Roboto"
  font-weight: 900
  font-size: 3em
  margin-top: auto
  text-align: center

.navlinks
  margin-bottom: 5px
  margin-top: auto

.navlink
  padding: 0px 50px 0px 50px

  &:hover
    color: colors.$pink

.navspan
  display: inline-block
  position: relative
  padding: 0 0 4px 0

  &:after
    content: ""
    position: absolute
    width: 100%
    transform: scaleX(0)
    height: 3px
    bottom: 0
    left: 0
    background-color: colors.$pink
    transform-origin: bottom right
    transition: transform 0.25s ease-out

  &:hover:after

    transform: scaleX(1)
    transform-origin: bottom left

.avatar
  height: 40px
  width: 40px
  border-radius: 100px
  border: 2px solid white
