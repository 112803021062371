@use '../colors'
.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-top: 50px

.title
  font-family: "Roboto"
  font-weight: 300
  font-size: 2.5em
  text-align: center

  &::selection
    background: colors.$l-pink

.highlight
  background-image: linear-gradient(120deg,colors.$l-pink,colors.$l-pink)
  background-repeat: no-repeat
  background-size: 100% 0.4em
  background-position: 0 88%

.container > form
  margin: 20px 0 20px 0
