.search_bar
  height: 3em
  position: relative
  margin-left: auto
  margin-right: auto
  width: 40%

.search_input
  width: 100%
  height: 100%
  border-radius: 1em
  border: none
  padding: 1em
  color: rgba(0, 0, 0, 0.45)
  font-size: 1.1em

.search_icon
  position: absolute
  top: 0
  right: 0.6em
  bottom: 0
  margin: auto auto auto 0
  width: 2em
  height: 2em
  z-index: 1
