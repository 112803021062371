@use 'colors'
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap")

html
  box-sizing: border-box
  font-size: 16px
  font-family: "Open Sans"

body
  background-color: colors.$bg

*,
*:before,
*:after
  box-sizing: inherit

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul
  margin: 0
  padding: 0
  font-weight: normal

ol,
ul
  list-style: none

img
  max-width: 100%
  height: auto

h4
  font-size: 20px

a
  text-decoration: none
  color: black
