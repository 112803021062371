.register
    height: 100vh
    width: 100vw
    display: flex
    align-items: center
    justify-content: center


    &__container
        display: flex
        flex-direction: column
        text-align: center
        background-color: #dcdcdc
        padding: 30px


    &__textBox
        padding: 10px
        font-size: 18px
        margin-bottom: 10px


    &__btn
        padding: 10px
        font-size: 18px
        margin-bottom: 10px
        border: none
        color: white
        background-color: black


    &__google
        background-color: #4285f4


    & div
        margin-top: 7px
