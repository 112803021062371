.container
  background-color: white
  border-radius: 10px
  padding: 1.6em
  margin: 1.3em
  min-width: 400px
  max-width: 600px
  width: 45%

.header
  display: flex
  justify-content: space-between

.category
  font-family: "Roboto"
  font-weight: bold
  font-size: 1.5em

.see_more
  color: #003049
  font-size: 0.9em
  text-decoration: underline

.product
  margin: 1em 0em


  &:last-child
    margin-bottom: 0

@media screen and (max-width: 992px)
  .container
    width: 80%
